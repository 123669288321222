import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

import { connect } from "react-redux";
import Global from "../../../language/Global.json";
import { setLanguage } from "../../../redux/actions-reducers/language";
import Product from "../../blocks/Product";
class Returns extends Component {
    render() {
        const {
            language,
            i18n: { Returns, ReturnRequest },
        } = this.props;

        return (
            <div
                className={`returns ${language === "rtl" && "returns-arabic"}`}
            >
                {/***************** header start *****************/}
                <div className="flex returns__header">
                    <div>{Returns}</div>
                    <div>
                        <button>{ReturnRequest}</button>
                    </div>
                </div>
                {/***************** header end *****************/}
                {/***************** cards start *****************/}
                <div>
                    <Row xl={4} lg={3} md={2} xs={1}>
                        <Col>
                            <Product />
                        </Col>
                        <Col>
                            <Product />
                        </Col>
                        <Col>
                            <Product />
                        </Col>
                        <Col>
                            <Product />
                        </Col>
                        <Col>
                            <Product />
                        </Col>
                        <Col>
                            <Product />
                        </Col>
                    </Row>
                </div>
                {/***************** cards end *****************/}
            </div>
        );
    }
}

const mapStateToProps = ({ language, currentUser }) => ({
    language: language.langCode,
    i18n: language.langCode === "ltr" ? Global.en : Global.ar,
    currentUser,
});
export default connect(mapStateToProps, { setLanguage })(Returns);
