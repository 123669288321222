import { apiService } from "./ApiService";
import { handleResponse } from "../utils/misc";
import { apiEndPoints } from "../api";
class ProductService {
    get guestId() {
        return localStorage.getItem("guestId");
    }

    async addOrRemoveFavorite(product_id) {
        try {
            const data = await apiService
                .authenticated()
                .post(apiEndPoints.product.addOrRemoveFavorite, { product_id, guest_id: apiService.accessToken === 0 ? this.guestId : 0 })
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }
    async addRemoveCompares(product_id) {
        try {
            const data = await apiService
                .authenticated()
                .post(apiEndPoints.product.addRemoveCompares, { product_id, guest_id: apiService.accessToken === 0 ? this.guestId : 0 })
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getProductDetails(machineName) {
        try {
            const data = await apiService
                .authenticated()
                .get(`${apiEndPoints.product.getProductDetails}?machine_name=${machineName}&guest_id=${apiService.accessToken === 0 ? this.guestId : 0}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getUserProductCompared() {
        try {
            const data = await apiService
                .authenticated()
                .get(`${apiEndPoints.product.getUserProductCompared}?guest_id=${apiService.accessToken === 0 ? this.guestId : 0}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async addToCart(product_option_id, qty) {
        try {
            const data = await apiService
                .authenticated()
                .post(`${apiEndPoints.product.addToCart}?guest_id=${apiService.accessToken === 0 ? this.guestId : 0}`, {
                    product_option_id,
                    qty,
                    guest_id: apiService.accessToken === 0 ? this.guestId : 0,
                })
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getProductsCustomersViewed(product_id) {
        try {
            const data = await apiService
                .unauthenticated()
                .get(`${apiEndPoints.product.getProductsCustomersViewed}?product_id=${product_id}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getProductRelated(machine_name) {
        try {
            const data = await apiService
                .unauthenticated()
                .get(`${apiEndPoints.product.getProductRelated}?machine_name=${machine_name}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getProductsFromSameBrand(machine_name) {
        try {
            const data = await apiService
                .unauthenticated()
                .get(`${apiEndPoints.product.getProductsFromSameBrand}?machine_name=${machine_name}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getProductSoldWith(machine_name) {
        try {
            const data = await apiService
                .unauthenticated()
                .get(`${apiEndPoints.product.getProductSoldWith}?machine_name=${machine_name}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getProductReviews(machine_name, page) {
        try {
            const data = await apiService
                .authenticated()
                .get(`${apiEndPoints.product.getSingleProductReviews}?machine_name=${machine_name}&page=${page}&guest_id=${apiService.accessToken === 0 ? this.guestId : 0}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }
    // async addToCart(product_option_id, qty) {
    //     try {
    //         const data = await apiService
    //             .authenticated()
    //             .post(apiEndPoints.product.addToCart, { product_option_id, qty, guest_id: apiService.accessToken === 0 ? this.guestId : 0 })
    //             .then(({ data }) => data);
    //         return handleResponse({ success: true, ...data });
    //     } catch ({ response }) {
    //         return handleResponse({ success: false, ...response?.data });
    //     }
    // }

    async addProductReview(reviewData) {
        try {
            const data = await apiService
                .authenticated()
                .post(apiEndPoints.product.addProductReview, { ...reviewData, guest_id: apiService.accessToken === 0 ? this.guestId : 0 })
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }
    //    `${apiEndPoints.cart.getUserFavorites}?machine_name=${machine_name}&page=${page}`
    // getProductReviews?machine_name=product_category_1_product_1&page=1
}

export const productService = new ProductService();
