import { apiEndPoints } from "../api";
import { handleResponse } from "../utils/misc";
import { apiService } from "./ApiService";

class LocationService {
    get guestId() {
        return localStorage.getItem("guestId");
    }

    async getUserLocation() {
        try {
            const data = await apiService
                .authenticated()
                .get(apiEndPoints.location.getUserLocation)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async addLocationSubmit(locationData) {
        try {
            const data = await apiService
                .authenticated()
                .post(apiEndPoints.location.addLocationSubmit, { ...locationData, guest_id: this.guestId ?? -1 })
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }
    async deleteUserLocation(locationData) {
        try {
            const data = await apiService
                .authenticated()
                .post(apiEndPoints.location.deleteUserLocation, locationData)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }
    async addUserLocation(locationData) {
        try {
            const data = await apiService
                .authenticated()
                .post(apiEndPoints.location.addUserLocation, locationData)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }
    async editUserLocation(locationData) {
        try {
            const data = await apiService
                .authenticated()
                .post(apiEndPoints.location.editUserLocation, locationData)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }
}

export const locationService = new LocationService();
