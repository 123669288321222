import { apiEndPoints } from "../api";
import { handleResponse } from "../utils/misc";
import { apiService } from "./ApiService";

class CartService {
    get guestId() {
        return localStorage.getItem("guestId");
    }

    async getUserCart() {
        try {
            const data = await apiService
                .authenticated()
                .get(`${apiEndPoints.cart.getUserCart}?guest_id=${apiService.accessToken === 0 ? this.guestId : 0}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getUserFavorites() {
        try {
            const data = await apiService
                .authenticated()
                .get(`${apiEndPoints.cart.getUserFavorites}?guest_id=${apiService.accessToken === 0 ? this.guestId : 0}`)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }

    async getCheckoutAmounts(cartData) {
        try {
            const data = await apiService
                .authenticated()
                .post(apiEndPoints.cart.getCheckoutAmounts, { ...cartData, guest_id: apiService.accessToken === 0 ? this.guestId : 0 })
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }
    async checkCouponIfValid(cartData) {
        try {
            const data = await apiService
                .authenticated()
                .post(apiEndPoints.cart.checkCouponIfValid, cartData)
                .then(({ data }) => data);
            return handleResponse({ success: true, ...data });
        } catch ({ response }) {
            return handleResponse({ success: false, ...response?.data });
        }
    }
}

export const cartService = new CartService();
